import config from '../../../components/config/ConfigFile.vue';
export default {
    async login(contex,payload){
        
        let url = null;
        
        if (payload.mode==='login') {
            url = config.login;//'http://localhost/appointment-app/api/rest/AccessUser/login';
            //url = contex.getters.getUrl + '/api/rest/AccessUser/login';
        } else {
            url = config.setUser;//'http://localhost/appointment-app/api/rest/AccessUser/setUser';
        }
        const response = await fetch(url,{
        //const response = await fetch('/api/rest/AccessUser/login',{
            method: 'POST',
            body: JSON.stringify({
                username: payload.email,
                password: payload.password
            })
        });
        const responseData = await response.json();
        if (payload.mode==='login') {
            if (responseData.response['status']==='success') {
                /*
                localStorage.setItem('userId',responseData.response['userId']);
                localStorage.setItem('userName',responseData.response['username']);
                localStorage.setItem('livello',responseData.response['livello']);
                localStorage.setItem('inizio',responseData.response['inizio']);
                localStorage.setItem('fine',responseData.response['fine']);*/
                contex.commit('setUser', {
                        userId: responseData.response['userId'],
                        userName: responseData.response['username'],
                        status: responseData.response['status'],
                        livello: responseData.response['livello'],
                        inizio: responseData.response['inizio'],
                        fine: responseData.response['fine'],
                        denominazione: responseData.response['denominazione']
                })
            }
        } else {
            if (responseData.status==='success') {
                console.log('status nel action login:'+responseData.status);
                contex.commit('setStatus', responseData.status);
            }
        }         
        if(!response.ok) {
            const error = new Error(responseData.message || ' errore in response data');
            throw error;
        }
        
    },
    tryLogin(context) {
        const livello = localStorage.getItem('livello');
        const userName = localStorage.getItem('userName');
        const userId = localStorage.getItem('userId');
        const inizio = localStorage.getItem('inizio');
        const fine = localStorage.getItem('fine');
        if(livello && userName && userId) {
            context.commit('setUser', {
                userId: userId,
                userName: userName,
                status: 'success',
                livello: livello,
                inizio: inizio,
                fine: fine
            })
        }
    },
    logout(context) {
        console.log('logout');
        const livello = localStorage.setItem('livello','');
        const userName = localStorage.setItem('userName','');
        const userId = localStorage.setItem('userId','');
        const inizio = localStorage.setItem('inizio','');
        const fine = localStorage.setItem('fine','');
        context.commit('setUser',{
            userId: userId,
            userName: userName,
            status: '',
            livello: livello,
            inizio: inizio,
            fine: fine
        })
    }
};