<template>
    <base-card>
        <form action="<?php echo URLROOT; ?>/prenotazioni/add" method="post" id="myForm">
            <div class="form-group">
                <label for="giorno">Filtra</label>
                <input class="form-control form-control-sm" 
                    type="date" name="giorno" v-model="giorno" @change="newDate"/><!--onchange="myFunction()"-->
            </div>
        </form>
    </base-card>
</template>
<script>
import { ref } from 'vue';

export default ({
    setup(_,context) {
        const giorno = ref('');
       /* onMounted(() => {
            var date = new Date(); // Or your date here
            console.log('giorno 1:'+date);
            selectDate.value  = date.getFullYear() + '-' +(date.getMonth() + 1) + '-' + date.getDate();
            console.log('giorno 2:'+selectDate.value);
        })*/

        function newDate(){
            console.log('giorno 3:'+giorno.value);
            var today = giorno.value;
            context.emit('change-date', today);
        }

        return { giorno, newDate }
    },
})
</script>
