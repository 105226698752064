export default {
    filteredAppointments(state) {
        return state.appointments;
    },
    hasAppointments(state){
        return state.appointments && state.appointments.length > 0;
    },
    getClienti(state){
        return state.clienti;
    },
    getStatus(state){
        return state.status;
    },
    getProfilo(state){
        return state.profilo;
    }
};