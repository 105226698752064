export default {
    setUser(state,payload){
        state.user.userId = payload.userId,
        state.user.userName = payload.userName,
        state.user.status = payload.status,
        state.user.livello = payload.livello,
        state.user.inizio = payload.inizio,
        state.user.fine = payload.fine,
        state.user.denominazione = payload.denominazione
    },
    setStatus(state,payload){
        state.status = payload
    }
}