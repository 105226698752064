<template>
    <section>
        <div v-if="isLoading">
            <base-spinner></base-spinner>
        </div>
        <base-card>
            <form @submit.prevent="setAppointments">
                <!--<base-badge :type="frontend" :title="mode"></base-badge>-->
                <h4>Nuovo appuntamento</h4>
                <h5>per il {{ giorno }}</h5>
                <h5>dalle {{ orario }}</h5>
                <div class="form-floating">
                        <input id="telefono" type="tel" class="form-control" name="telefono" v-model.trim="telefono"> 
                        <label for="telefono">Telefono</label>
                </div>
                <div class="form-floating">
                        <input id="cognome" required type="text" class="form-control" placeholder="cognome" name="cognome" v-model.trim="cognome">
                        <label for="cognome">Cognome<sup>*</sup></label>
                </div>
                <div class="form-floating">
                        <input id="nome" type="text" class="form-control" placeholder="nome" name="nome" v-model.trim="nome">
                        <label for="nome">Nome</label>
                </div>
                <div class="form-floating">
                        <input id="email" type="text" class="form-control" placeholder="email" name="email" v-model.trim="email"> 
                        <label for="email">Email</label>
                </div>
                <div class="form-floating">
                        <input id="messaggio" type="textarea" rows="2" class="form-control" placeholder="messaggio" name="messaggio" v-model.trim="messaggio">
                        <label for="messaggio">Messaggio</label>
                </div>
                <div class="form-floating">
                        <select id="lunghezza" class="form-select" placeholder="lunghezza" name="lunghezza" value="1" v-model="lunghezza">
                            <option value="1">30 minuti</option>
                            <option value="2">1 ora</option>
                            <option value="3">1 ora e 30 minuti</option>
                            <option value="4">2 ore</option>
                            <option value="5">2 ore e 30 minuti</option>
                            <option value="6">3 ore</option>
                            <option value="7">3 ore e 30 minuti</option>
                            <option value="8">4 ore</option>
                        </select>
                        <label for="lunghezza">Lunghezza</label>
                </div>
                <div class="form-control">
                    <base-button mode="btn-success" :disabled="!cognome">Inserisci</base-button>
                </div>
            </form>
            <div class="row mt-1">
                <div class="col-md-6 mx-auto">
                    <router-link class="nav-link" mode="link-class" :to="'/appointment'">Back</router-link>
                </div>
            </div>
        </base-card>
    </section>
</template>

<script>
import { ref, onBeforeMount, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { toast } from 'vue3-toastify';
//import 'vue3-toastify/dist/index.css';

export default ({
    props: ['giorno','appId', 'orario','mode'],
    setup(props) {
        const isLoading = ref(false);
        const cognome = ref('');
        const nome = ref('');
        const telefono = ref(null);
        const messaggio = ref('');
        const email = ref('');
        const lunghezza = ref('1');
        const store = useStore();
        const router = useRouter();
        const clienti = ref(null);
        const cliente = ref(null);

        watch(telefono, function(newValue){
            selectCliente(newValue);
        })

        /*const clienti = computed(function(){
            return store.getters['appointments/getClienti'];
        })*/
        onBeforeMount(() => {
            try {
                store.dispatch('appointments/getClienti',{
                    id: store.getters.getUserId,
                    livello: store.getters.getLivello
                });
            } catch (error){
                error.value = error.value.message || 'Something went wrong';
            }
        })
        onMounted(() => {
            clienti.value = store.getters['appointments/getClienti'];
        })

        async function setAppointments(){
            isLoading.value = true;
            try {
                await store.dispatch('appointments/setAppointments',{
                    userId: store.getters.getUserId,
                    cognome: cognome.value,
                    nome: nome.value,
                    email: email.value,
                    telefono: telefono.value,
                    messaggio: messaggio.value,
                    lunghezza: lunghezza.value,
                    giorno: props.giorno,
                    appId: props.appId,
                    livello: store.getters.getLivello
                });
                } catch (error){
                    error.value = error.value.message || 'Something went wrong';
                }
                isLoading.value = false;
                const res = store.getters['appointments/getStatus'];
                if(res.status==="success") {
                    toast.success(res.message, { autoClose : 2000 });
                } else {
                    toast.error(res.message, { autoClose : 2000 });
                }
                setTimeout(function() {
                    if (props.mode == 'tutti') {
                        router.replace('/allAppointment');
                    } else {
                        router.replace('/appointment');
                    }
                },1000);
            }

            function selectCliente(tel){
                clienti.value = store.getters['appointments/getClienti'];
                cliente.value = clienti.value.find((usr) => usr.telefono === tel);
                if(cliente.value){
                    nome.value = cliente.value.nome;
                    cognome.value = cliente.value.cognome;
                    email.value = cliente.value.email;
                }
            } 

        return { cognome,nome,email,telefono,messaggio, lunghezza,isLoading, setAppointments, clienti }
    },
})
</script>
