<template>
    <!--<div class="card card-body bg-light mt-5">-->
        <div  class="block" :class="giorno ? 'occupato' : 'libero'">
        <slot>
            <li>
                <!--<h5 v-if="giorno">{{ giorno }}</h5>
                <h5 v-else>{{ giorno_sel }}</h5>-->
                <h6>{{ orario }}</h6>
                <router-link v-if="!giorno" class="nav-link" mode="link-class" :to="'/appointmentNew/'+id+'/'+giorno_sel+'/'+orario+'/tutti'">nuovo</router-link>
            </li>
        </slot>
    </div>
</template>

<script>
import 'vue3-toastify/dist/index.css';
export default ({
    props: ['id','giorno','orario', 'giorno_sel','cognome','nome','telefono','messaggio','id_a'],
    data() {
        return {
            showDetails: false
        }
    },
    computed: {
        formatGiorno() {
            return 'giorno:'+this.giorno;
        },
    },
    methods: {
        getFullName(){
            console.log(this.cognome);
            console.log(this.nome);
            return this.cognome + ' ' + this.nome;
        }
    }
})
</script>


<style scoped>
.card {
    padding: 1rem;
    margin: 2rem auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    border-radius: 12px;
    max-width: 20rem;
    float: left;
}
.block {
    border-radius: 12px;
    padding: 1rem;
    margin: 2rem auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    width:              140px;
    height:             70px;
    margin:             5px;        
}
.libero {
    border-style: solid;
    border-color: green
}
.occupato {
    border-style: solid;
    border-color: red;
}
</style>