import config from '../../../components/config/ConfigFile.vue';
export default {
    async getAppointments(contex,payload){
        let url = '';
        if(payload.mode ==='getApp') {
            url = config.getAppointments;
        } else {
            url = config.getAllAppointments;
        }
        let message = null;
        const response = await fetch(url,{
            method: 'POST',
            body: JSON.stringify({
                id: payload.id,
                livello: payload.livello,
                inizio: payload.inizio,
                fine: payload.fine,
                giorno: payload.giorno
            })
        });
        const responseData = await response.json();
        const results = [''];
        if (responseData.status==='success') {
            for (const res in responseData.data) {
                results.push({
                    id: responseData.data[res].id,
                    giorno: responseData.data[res].giorno,
                    orario: responseData.data[res].orario,
                    cognome: responseData.data[res].cognome,
                    nome: responseData.data[res].nome,
                    telefono: responseData.data[res].telefono,
                    messaggio: responseData.data[res].messaggio,
                    id_a: responseData.data[res].id_a,
                    today: responseData.data[res].today
                });
            }
        } else {
            message = responseData.message;
        }
        if(!response.ok) {
            const error = new Error(responseData.message || ' errore in response data');
            throw error;
        }
        this.result = results;
        this.result.message = message;
        contex.commit('getAppointments', this.result);
    },

    async setAppointments(contex,payload){
        let url = config.setAppointments;//'http://localhost/appointment-app/api/rest/Appuntamenti/getAppointments';
        let message = null;
        const response = await fetch(url,{
        //const response = await fetch('/api/rest/AccessUser/login',{
            method: 'POST',
            body: JSON.stringify({
                userId: payload.userId,
                cognome: payload.cognome,
                nome: payload.nome,
                email: payload.email,
                telefono: payload.telefono,
                messaggio: payload.messaggio,
                lunghezza: payload.lunghezza,
                giorno: payload.giorno,
                appId: payload.appId,
                livello: payload.livello
            })
        });
        const responseData = await response.json();
        let results = '';
        if (responseData.status==='success') {
            message = responseData.message;
            results = responseData.status;
        } else {
            message = responseData.message;
            results = responseData.status;
        }
        if(!response.ok) {
            const error = new Error(responseData.message || ' errore in response data');
            throw error;
        }
        this.result.status = results;
        this.result.message = message;
        contex.commit('setStatus', this.result);
    },

    async getClienti(contex,payload){
        let url = config.getClienti;
        let message = null;
        const response = await fetch(url,{
            method: 'POST',
            body: JSON.stringify({
                id: payload.id,
                livello: payload.livello
            })
        });
        const responseData = await response.json();
        const results = [''];
        if (responseData.status==='success') {
            for (const res in responseData.data) {
                results.push({
                    telefono: responseData.data[res].telefono,
                    nome: responseData.data[res].nome,
                    cognome: responseData.data[res].cognome,
                    email: responseData.data[res].email
                });
            }
        } else {
            message = responseData.message;
        }
        if(!response.ok) {
            const error = new Error(responseData.message || ' errore in response data');
            throw error;
        }
        this.result = results;
        this.result.message = message;
        contex.commit('getClienti', this.result);
    },

    async deleteAppuntamento(contex,payload){
        let url = config.deleteAppuntamento;//'http://localhost/appointment-app/api/rest/Appuntamenti/getAppointments';
        let message = null;
        const response = await fetch(url,{
        //const response = await fetch('/api/rest/AccessUser/login',{
            method: 'POST',
            body: JSON.stringify({
                id: payload.id,
                livello: payload.livello
            })
        });
        const responseData = await response.json();
        let results = '';
        if (responseData.status==='success') {
            message = responseData.message;
            results = responseData.status;
        } else {
            message = responseData.message;
            results = responseData.status;
        }
        if(!response.ok) {
            const error = new Error(responseData.message || ' errore in response data');
            throw error;
        }
        this.result.status = results;
        this.result.message = message;
        contex.commit('setStatus', this.result);
    },

    async getProfilo(contex,payload){
        let url = config.getProfilo;
        let message = null;
        const response = await fetch(url,{
            method: 'POST',
            body: JSON.stringify({
                id: payload.id,
                livello: payload.livello
            })
        });
        const responseData = await response.json();
        const results = {};
        if (responseData.status==='success') {
            for (const res in responseData.data) {
                console.log('---today nel for:'+responseData.data[res].today);
                results.telefono = responseData.data[res].telefono,
                results.denominazione = responseData.data[res].denominazione,
                results.indirizzo = responseData.data[res].indirizzo,
                results.email = responseData.data[res].email,
                results.inizio = responseData.data[res].inizio,
                results.fine = responseData.data[res].fine
            }
        } else {
            message = responseData.message;
        }
        if(!response.ok) {
            const error = new Error(responseData.message || ' errore in response data');
            throw error;
        }
        this.result = results;
        this.result.message = message;
        contex.commit('getProfilo', this.result);
    },

    async setProfilo(contex,payload){
        let url = config.setProfilo;//'http://localhost/appointment-app/api/rest/Appuntamenti/getAppointments';
        let message = null;
        const response = await fetch(url,{
        //const response = await fetch('/api/rest/AccessUser/login',{
            method: 'POST',
            body: JSON.stringify({
                id: payload.id,
                livello: payload.livello,
                profilo: payload.profilo
            })
        });
        const responseData = await response.json();
        let results = '';
        if (responseData.status==='success') {
            message = responseData.message;
            results = responseData.status;
        } else {
            message = responseData.message;
            results = responseData.status;
        }
        if(!response.ok) {
            const error = new Error(responseData.message || ' errore in response data');
            throw error;
        }
        this.result.status = results;
        this.result.message = message;
        contex.commit('setStatus', this.result);
    },

    async cambiaPassword(contex,payload){
        let url = config.cambiaPassword;//'http://localhost/appointment-app/api/rest/Appuntamenti/getAppointments';
        let message = null;
        const response = await fetch(url,{
        //const response = await fetch('/api/rest/AccessUser/login',{
            method: 'POST',
            body: JSON.stringify({
                id: payload.id,
                livello: payload.livello,
                new_password: payload.new_password
            })
        });
        const responseData = await response.json();
        let results = '';
        if (responseData.status==='success') {
            message = responseData.message;
            results = responseData.status;
        } else {
            message = responseData.message;
            results = responseData.status;
        }
        if(!response.ok) {
            const error = new Error(responseData.message || ' errore in response data');
            throw error;
        }
        this.result.status = results;
        this.result.message = message;
        contex.commit('setStatus', this.result);
    },

};