<template>
<!--<div class="screenFourth">-->
  <div class="container">
    <!--<div class="immagine">-->
      <the-header></the-header>
      <router-view></router-view>
    <!--</div>-->
  </div> 
<!--</div>-->
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';

export default {
  name: 'App',
  components: { 
    TheHeader
   },
   created() {
    null;
    this.$store.dispatch('tryLogin');
   }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.immagine_ {
  opacity: 0,5;
  background-image: url('./img/section-3-bg.jpg');
  background-repeat: repeat-y;
  background-size: 100% auto;
  background-position: center;
  width: 80vw;
}
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.screenFourth{
    background-image: url('./img/section-3-bg.jpg');
    background-size: cover;
    background-position: top;
    width: 100%;
    min-height: 1380px;
}

@media screen and (max-width: 760px){
    .container{ width: 100vw; margin: 0; padding: 0; }
}
</style>
