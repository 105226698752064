import { createStore } from 'vuex';
import AppointmentModule from './modules/appointments/index.js';
import UserModule from './modules/auth/index.js';

const store = createStore({
    modules: {
        appointments: AppointmentModule,
        user: UserModule
    }
});

export default store;