import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    //namespaced: true,
    state() {
        return {
            user: 
                {
                    userId: null,
                    userName: null,
                    status: null,
                    livello: null,
                    inizio: null,
                    fine: null,
                    base_url: 'http://localhost/appointment-app'
                },
            status: ''
        };
    },
    mutations,
    actions,
    getters
}