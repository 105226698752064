<template>
<div class="card">
    <h1>{{ formatDay }}</h1>
</div>
</template>
<script>

import { computed } from 'vue';
export default ({
    props: ['giorno'],
    setup(props) {
        const formatDay = computed(function(){
            var options = {'weekday': 'long', 'month': '2-digit', 'day': '2-digit'};
            return new Date(props.giorno).toLocaleString('it-IT', options);
        })
        return { formatDay }
    },
})
</script>
<style scoped>
.card {
    padding: 1rem;
    margin: 2rem auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    border-radius: 12px;
}
</style>
