import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            appointments: [
                {
                    id: ''
                }
            ],
            clienti: [
                {
                    cognome: ''
                }
            ],
            status : '',
            profilo : {
                    indirizzo: '',
                    denominazione: '',
                    email: '',
                    telefono: '',
                    inizio: '',
                    fine: ''
                }
        };
    },
    mutations,
    actions,
    getters
}