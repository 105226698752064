import { createApp, defineAsyncComponent } from 'vue';
import App from './App.vue';
import router from './router.js';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import store from './store/index.js';
import BaseCard from './components/ui/BaseCard.vue';
import BaseView from './components/ui/BaseView.vue';
import ButtonView from './components/ui/ButtonView.vue';
import BaseButton from './components/ui/BaseButton.vue';
//import BaseBadge from './components/ui/BaseBadge.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDay from './components/ui/BaseDay.vue';
//import BaseDialog from './components/ui/BaseDialog.vue';

const BaseDialog = defineAsyncComponent(() => import('./components/ui/BaseDialog.vue'));
const BaseBadge = defineAsyncComponent(() => import('./components/ui/BaseBadge.vue'));

const app = createApp(App)
app.use(router);
app.use(store);

app.component('base-card', BaseCard);
app.component('base-view', BaseView);
app.component('button-view', ButtonView);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);
app.component('base-day', BaseDay);

app.mount('#app')

