export default {
    getAccess(state) {
        return !!state.user.userId;
    },
    getUserId(state) {
        return state.user.userId;
    },
    getUserName(state) {
        return state.user.userName;
    },
    getLivello(state) {
        return state.user.livello;
    },
    getInizio(state) {
        return state.user.inizio;
    },
    getFine(state) {
        return state.user.fine;
    },
    getUserStatus(state) {
        return state.user.status;
    },
    getUrl(state){
        return state.user.get_url;
    },
    getDenominazione(state){
        return state.user.denominazione;
    },
    getStatus(state){
        return state.status;
    }
};