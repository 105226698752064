<template>
    <section>
        <!--<h2>{{ format_day }}</h2>-->
        <base-day :giorno="giorno"></base-day>
        <filter-day @change-date="changeFilter" mode="card-20"></filter-day>
    </section>
    <div v-if="isLoading">
        <base-spinner></base-spinner>
    </div>
    <section>
        <div v-if="hasAppointments"> 
            <ul>
                <li v-for="appointment in filteredAppointments" :key="appointment.id">
                    <base-view v-if="appointment.id"
                        :id="appointment.id"
                        :giorno="appointment.giorno"
                        :giorno_sel="giorno"
                        :orario="appointment.orario"
                        :cognome="appointment.cognome"
                        :nome="appointment.nome"
                        :telefono="appointment.telefono"
                        :messaggio="appointment.messaggio"
                        :id_a="appointment.id_a"
                        @deleteApp="deleteAppointment"
                    ></base-view>
                </li>
            </ul>
        </div>
        <div v-else>
            <span>Nessun Appuntamento</span>
        </div>
    </section>
    <base-dialog :show="!!error || filteredAppointments.message" title="Attenzione" @close="handleError">
<p>{{ error }} {{ filteredAppointments.message }}</p>
</base-dialog>
</template>
<script>
import { ref, computed, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import FilterDay from '../../components/ui/FilterDay.vue';
import { useDateFormat, useNow } from '@vueuse/core';
//import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

export default ({
    components: { 
        FilterDay
    },
    setup() {
        const isLoading = ref(false);
        const error = ref(null);
        const giorno = ref(null);
        const format_day = ref('');
        const store = useStore();
        //const router = useRouter();

        const filteredAppointments = computed(function(){
            return store.getters['appointments/filteredAppointments'];
        })
        const hasAppointments = computed(function(){
            return store.getters['appointments/hasAppointments'];
        })
        computed(function(){
            getAppointments();
            return null;
        })
        onBeforeMount(() => {
            console.log('onBeforeMount');
        })
        onMounted(() => {
            console.log('onMounted');
            var date = new Date();
            giorno.value  = date.getFullYear() + '-' +(date.getMonth() + 1) + '-' + date.getDate();
            format_day.value = useDateFormat(useNow(),'dddd, D MMMM, YYYY');
            getAppointments();
        })
        
        async function getAppointments(){
            isLoading.value = true;
            try {
                await store.dispatch('appointments/getAppointments',{
                    id: store.getters.getUserId,
                    livello: store.getters.getLivello,
                    inizio: store.getters.getInizio,
                    fine: store.getters.getFine,
                    giorno: giorno.value,
                    mode: 'getApp'
                });
            } catch (error){
                error.value = error.value.message || 'Something went wrong';
            }
            isLoading.value = false;
        }
        function handleError(){
            error.value = null;
            filteredAppointments.value.message = null;
        }
        function changeFilter(t){
            giorno.value = t;
            format_day.value = useDateFormat(giorno.value,"ddd D MMMM YYYY");
            getAppointments();
        }

        async function deleteAppointment(id){
            isLoading.value = true;
            try {
                await store.dispatch('appointments/deleteAppuntamento',{
                    id: id,
                    livello: store.getters.getLivello
                });
                } catch (error){
                    error.value = error.value.message || 'Something went wrong';
                }
                isLoading.value = false;
                const res = store.getters['appointments/getStatus'];
                if(res.status==="success") {
                    toast.success(res.message, { autoClose : 1000 });
                } else {
                    toast.error(res.message, { autoClose : 1000 });
                }
                getAppointments();
            }
        
        return { isLoading, error, filteredAppointments, hasAppointments, getAppointments, handleError, changeFilter, giorno, format_day, deleteAppointment }
    },
})
</script>

<style scoped>
ul {
    list-style:none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>
