<script>
export default ({
    /*login: 'http://localhost/appointment-app/api/rest/AccessUser/login',
    setUser: 'http://localhost/appointment-app/api/rest/AccessUser/setUser',
    getAppointments: 'http://localhost/appointment-app/api/rest/Appuntamenti/getAppointments',
    getAllAppointments: 'http://localhost/appointment-app/api/rest/Appuntamenti/getAllAppointments',
    setAppointments: 'http://localhost/appointment-app/api/rest/Appuntamenti/setAppointments',
    getClienti: 'http://localhost/appointment-app/api/rest/Appuntamenti/getClienti',
    deleteAppuntamento: 'http://localhost/appointment-app/api/rest/Appuntamenti/deleteAppuntamento',
    getProfilo: 'http://localhost/appointment-app/api/rest/Profili/getProfilo',
    setProfilo: 'http://localhost/appointment-app/api/rest/Profili/modifica',
    cambiaPassword: 'http://localhost/appointment-app/api/rest/Profili/cambiaPassword',*/
    login: 'https://appointment-app.tillmaster.it/api/rest/AccessUser/login',
    setUser: 'https://appointment-app.tillmaster.it/api/rest/AccessUser/setUser',
    getAppointments: 'https://appointment-app.tillmaster.it/api/rest/Appuntamenti/getAppointments',
    getAllAppointments: 'https://appointment-app.tillmaster.it/api/rest/Appuntamenti/getAllAppointments',
    setAppointments: 'https://appointment-app.tillmaster.it/api/rest/Appuntamenti/setAppointments',
    getClienti: 'https://appointment-app.tillmaster.it/api/rest/Appuntamenti/getClienti',
    deleteAppuntamento: 'https://appointment-app.tillmaster.it/api/rest/Appuntamenti/deleteAppuntamento',
    getProfilo: 'https://appointment-app.tillmaster.it/api/rest/Profili/getProfilo',
    setProfilo: 'https://appointment-app.tillmaster.it/api/rest/Profili/modifica',
    cambiaPassword: 'https://appointment-app.tillmaster.it/api/rest/Profili/cambiaPassword',
})
</script>