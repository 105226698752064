<template>
    <div v-if="isLoading">
        <base-spinner></base-spinner>
    </div>
    <section>
        <div v-if="hasAppointments"> 
            <ul>
                <li v-for="filtered in filteredDays" :key="filtered">
                    <base-day :giorno="filtered"></base-day>
                    <ul>
                        <li v-for="appointment in filteredD(filtered)" :key="appointment.id">
                            <button-view v-if="appointment.id"
                            :id="appointment.id"
                            :giorno="appointment.giorno"
                            :giorno_sel="appointment.today"
                            :orario="appointment.orario"
                            :cognome="appointment.cognome"
                            :nome="appointment.nome"
                            :telefono="appointment.telefono"
                            :messaggio="appointment.messaggo"
                            :id_a="appointment.id_a"
                            ></button-view>
                        </li>
                    </ul>
                    <label for=""></label>
                </li>
            </ul>
        </div>
        <div v-else>
            <span>Nessun Appuntamento</span>
        </div>
    </section>
    <base-dialog :show="!!error || filteredAppointments.message" title="Attenzione" @close="handleError">
<p>{{ error }} {{ filteredAppointments.message }}</p>
</base-dialog>
</template>
<script>
import { ref, computed, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useDateFormat, useNow } from '@vueuse/core';
import { toast } from 'vue3-toastify';

export default ({
    setup() {
        const isLoading = ref(false);
        const error = ref(null);
        const giorno = ref(null);
        const format_day = ref('');
        const store = useStore();
        let filteredDays = [];
        let array_app = [];
        //const router = useRouter();

        const filteredAppointments = computed(function(){
            return store.getters['appointments/filteredAppointments'];
        })
        const hasAppointments = computed(function(){
            return store.getters['appointments/hasAppointments'];
        })
        function filteredD(day){
            array_app = [];
            filteredAppointments.value.filter(app => {
                if (day < 10) {
                    day = '0' + day;
                }
                if (app.today === day){
                    array_app.push(app);//return true;
                }
            });
            return array_app;
        }

        computed(function(){
            getAppointments();
            return null;
        })
        onBeforeMount(() => {
            console.log('onBeforeMount');
        })
        onMounted(() => {
            console.log('onMounted');
            var date = new Date();
            giorno.value  = date.getFullYear() + '-' +(date.getMonth() + 1) + '-' + date.getDate();
            format_day.value = useDateFormat(useNow(),'dddd, D MMMM, YYYY');
            
            for (let i=0; i<28; i++){
                filteredDays.push(formatGiorno(i));
            }
            getAppointments();
        })

        function MostraTutto(){
            giorno.value = null;
            getAppointments();
        }
        
        async function getAppointments(){
            isLoading.value = true;
            try {
                await store.dispatch('appointments/getAppointments',{
                    id: store.getters.getUserId,
                    livello: store.getters.getLivello,
                    inizio: store.getters.getInizio,
                    fine: store.getters.getFine,
                    giorno: giorno.value,
                    today: store.getters.today,
                    mode: 'getAll'
                });
            } catch (error){
                error.value = error.value.message || 'Something went wrong';
            }
            isLoading.value = false;
        }
        function handleError(){
            error.value = null;
            filteredAppointments.value.message = null;
        }

        async function deleteAppointment(id){
            isLoading.value = true;
            try {
                await store.dispatch('appointments/deleteAppuntamento',{
                    id: id,
                    livello: store.getters.getLivello
                });
                } catch (error){
                    error.value = error.value.message || 'Something went wrong';
                }
                isLoading.value = false;
                const res = store.getters['appointments/getStatus'];
                if(res.status==="success") {
                    toast.success(res.message, { autoClose : 2000 });
                } else {
                    toast.error(res.message, { autoClose : 2000 });
                }
                getAppointments();
            }
            function formatGiorno(n){
                var d = new Date();
                d.setDate(d.getDate() + n);
                let month = d.getMonth() + 1;
                if (d.getMonth() + 1 < 10) {
                    let n = d.getMonth() + 1;
                    month = '0'+ n;
                }
                var day = d.getDate();
                if (d.getDate() < 10) {
                    day = '0'+d.getDate();
                }
                return d.getFullYear() + '-' + month + '-' + day;
            }
        
        return { isLoading, error, filteredAppointments, hasAppointments, getAppointments, handleError, giorno, format_day, deleteAppointment, MostraTutto, filteredDays, filteredD }
    },
})
</script>

<style scoped>
ul {
    list-style:none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>
