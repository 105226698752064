import { defineAsyncComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

//import UserAuth from './pages/auth/UserAuth.vue';
//import NotFound from './pages/NotFound.vue';
import AppointmentList from './pages/appointments/AppointmentList.vue';
import AllAppointmentList from './pages/appointments/AllAppointmentList.vue';
import AppointmentNew from './pages/appointments/AppointmentNew.vue';
//import ProfiloNew from './pages/profiles/ProfiloNew.vue';
//import MyHome from './pages/auth/MyHome.vue';
import store from './store/index.js';

const NotFound = defineAsyncComponent(() => import('./pages/NotFound.vue'));
const ProfiloNew = defineAsyncComponent(() => import('./pages/profiles/ProfiloNew.vue'));
const MyHome = defineAsyncComponent(() => import('./pages/auth/MyHome.vue'));
const UserAuth = defineAsyncComponent(() => import('./pages/auth/UserAuth.vue'));

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/home' },
        { path: '/home', component: MyHome },
        { path: '/profilo', component: ProfiloNew, meta: { requiresAuth: true} },
        { path: '/appointment/', component: AppointmentList, children: [
           { path: 'appointmentNew' , component: AppointmentNew, props: true, meta: { requiresAuth: true} }
        ] },
        { path: '/appointmentNew/:appId/:giorno/:orario/:mode', component: AppointmentNew, props: true, meta: { requiresAuth: true} },
        { path: '/appointmentList', component: AppointmentList, meta: { requiresAuth: true} },
        { path: '/allAppointment', component: AllAppointmentList, meta: { requiresAuth: true} },
        { path: '/auth/:modeId', component: UserAuth, props: true, meta: { requiresUnAuth: true}  },
        { path: '/:notFound(.*)', component: NotFound }
    ]
});
router.beforeEach(function(to,_,next){
    console.log('nel router:'+store.getters.getAccess);
    if(to.meta.requiresAuth && !store.getters.getAccess){
        next('/auth')
    } else if (to.meta.requiresUnAuth && store.getters.getAccess) {
        next('/appointmentList')
    } else {
        next()
    }
})

export default router;