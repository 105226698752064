<template>
    <div class="collapse" id="navbarToggleExternalContent">
        <div class="bg-dark p-4">
            <h5 class="text-white h4">Collapsed content</h5>
            <span class="text-muted">Toggleable via the navbar brand.</span>
        </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">{{ denominazione }}</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
            <router-link class="nav-link" to="/Home"><i class="fa fa-home"></i> Home</router-link>
            </li>
            <li class="nav-item" v-if="getUser">
                <router-link class="nav-link" to="/profilo"><i class="fa fa-user"></i> Profilo</router-link>
            </li>
            <li class="nav-item" v-if="getUser">
                <router-link class="nav-link" to="/appointment"><i class="fa fa-address-book"></i> Appuntamenti</router-link>
            </li>
            <li class="nav-item" v-if="getUser">
                <router-link class="nav-link" to="/allAppointment"><i class="fa fa-search"></i> Disponibilità mensili</router-link>
            </li>
            <!--<li class="nav-item dropdown" v-if="getUser">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown link
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li><a class="dropdown-item" href="#" v-if="getUser">Action</a></li>
            </ul>
            </li>-->
        </ul>
        <!--<form class="d-flex">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success" type="submit">Search</button>
        </form>-->
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" v-if="!getUser">
                    <router-link class="nav-link" to="/auth/signup"><i class="fa fa-user"></i> Register</router-link>
            </li>
            <li class="nav-item" v-if="!getUser">
                    <router-link class="nav-link" :to="'/auth/login'"><i class="fa fa-sign-in"></i> Login</router-link>
            </li>
            <li v-if="getUser"><base-button @click="logout"><i class="fa fa-sign-out"></i> Logout</base-button></li>
        </ul>
        </div>
    </div>
    </nav>
</template>
<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default ({
    setup() {
        const router = useRouter();
        const store = useStore();
        const denominazione = computed(function(){
            return store.getters.getDenominazione;
        });

        const getUser = computed(function(){
            return store.getters['getAccess'];
        })
        const logout = function(){
            console.log('nel logout');
            store.dispatch('logout');
            router.replace('/home');
        }
        return { getUser, logout, denominazione }
    },
})
</script>

<!--
<script>
export default {
    computed : {
        getUser() {
            return this.$store.getters['getAccess'];
        }
    },
    methods : {
        logout() {
            this.$store.dispatch('logout');
            this.$router.replace('/home');
        }
    }
}
</script>-->
