<template>
    <button v-if="!link" class="btn btn-sm btn-block" :class="mode">
        <slot></slot>
    </button>
    <router-link v-else :to="to" class="btn btn-sm btn-link btn-block"  :class="mode">
        <slot></slot>
    </router-link>
</template>

<script>

export default {
    props: {
        link: {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: String,
            required: false,
            default: '/'
        },
        mode: {
            type: String,
            required: false,
            default: 'btn-secondary'
        }
    }
}
</script>

<style scoped>
.btn {
    margin-right: 1px;
}
</style>
