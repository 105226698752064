export default {
    getAppointments(state,payload){
        state.appointments = payload;
    },
    setStatus(state,payload){
        state.status = payload;
    },
    getClienti(state,payload){
        state.clienti = payload;
    },
    getProfilo(state,payload){
        state.profilo = payload;
    }
};