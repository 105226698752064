<template>
<base-dialog :show="showDetails" :title="orario" @close="closeDetails">
    <h5>{{ cognome }} {{ nome }}</h5>
    <p>telefono:{{ telefono }}</p>
    <p>{{ messaggio }}</p>
</base-dialog>
    <!--<div class="card card-body bg-light mt-5">-->
        <div  class="block" :class="giorno ? 'occupato' : 'libero'">
        <slot>
            <li>
                <h5>{{ orario }}</h5>
                <h6 v-if="giorno">{{ cognome }}</h6>
                <base-button v-if="giorno" mode="btn-danger" @click="deleteAppointment(id_a)">Cancella</base-button>
                <base-button v-if="giorno" @click="details">Dettagli</base-button>
                <router-link v-if="!giorno" class="nav-link" mode="link-class" :to="'/appointmentNew/'+id+'/'+giorno_sel+'/'+orario+'/giornalieri'">nuovo</router-link>
            </li>
        </slot>
    </div>
</template>

<script>
import 'vue3-toastify/dist/index.css';
export default ({
    props: ['id','giorno','orario', 'giorno_sel','cognome','nome','telefono','messaggio','id_a'],
    emit: ['delete-app'],
    data() {
        return {
            showDetails: false
        }
    },
    computed: {
        formatGiorno() {
            return 'giorno:'+this.giorno;
        },
    },
    methods: {
        details(){
            this.showDetails = true;
        },
        closeDetails(){
            this.showDetails = false;
        },
        getFullName(){
            console.log(this.cognome);
            console.log(this.nome);
            return this.cognome + ' ' + this.nome;
        },
        deleteAppointment(id){
            if (confirm("Sei sicuro di cancellare l'appuntamento?")) {
                this.$emit('delete-app',id);
            }
        }
    }
})
</script>


<style scoped>
.card {
    padding: 1rem;
    margin: 2rem auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    border-radius: 12px;
    max-width: 20rem;
    float: left;
}
.block {
    border-radius: 12px;
    padding: 1rem;
    margin: 2rem auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    width:              160px;
    height:             160px;
    margin:             5px;        
}
.libero {
    border-style: solid;
    border-color: green
}
.occupato {
    border-style: solid;
    border-color: red;
}
</style>