<template>
    <div class="card card-body bg-light mt-5" :class="mode">
        <slot></slot>
    </div>
</template>
<script>

export default ({
    props: {
        mode: {
            type: String,
            required: false,
            default: 'card-30'
        }
    }
})
</script>


<style scoped>
.card {
    padding: 1rem;
    margin: 2rem auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    border-radius: 12px;
}
.card-20 {
    max-width: 20rem;
}
.card-30 {
    max-width: 30rem;
}
</style>